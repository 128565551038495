import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Grommet, grommet } from "grommet";
import { deepMerge } from "grommet/utils";
import { customColors } from "./customColors";
import ErrorBoundary from "./ErrorBoundary";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const theme = deepMerge(
  grommet,
  {
    global: {
      colors: {},
      font: {
        family: "Roboto",
        size: "18px",
        height: "20px",
      },
    },
  },
  customColors
);

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Grommet theme={theme} full>
        <App />
      </Grommet>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
