import React, { useEffect, useRef } from "react";
import { Box, Button, RangeInput, TextArea, Text } from "grommet";
import { Send } from "grommet-icons";
import styled from "styled-components";
import appInsights from "../appInsights";

interface PromptProps {
  onSend?: (value: string, temperature: number) => void;
}

const StyledTextArea = styled(TextArea)`
  height: auto;
  overflow: hidden;
  padding-right: 50px;
  resize: none;
`;

const SendButton = styled(Button)`
  position: absolute;
  right: 5px;
  bottom: -2px;
`;

const RangeContainer = styled(Box)`
  padding-top: 20px;
  padding-left: 10%;
  padding-right: 10%;
`;

const RangeText = styled(Text)`
  flex-basis: 5rem;
`;

export const Prompt: React.FC<PromptProps> = ({ onSend }) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [temperature, setTemperature] = React.useState(0.6);
  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const isMobile = () => {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    };

    if (e.key === "Enter" && !e.shiftKey && !isMobile()) {
      e.preventDefault();
      appInsights.trackEvent({
        name: "sendPrompt",
        properties: {
          // exampleProperty: "exampleValue",
        },
      });
      handleSend(e.currentTarget.value);
      e.currentTarget.value = "";
      adjustTextAreaHeight();
    }
  };

  const handleInput = () => {
    if (textAreaRef.current) {
      adjustTextAreaHeight();
    }
  };

  const adjustTextAreaHeight = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      const height = textAreaRef.current.scrollHeight - 20;
      textAreaRef.current.style.height = `${height}px`;
    }
  };

  const handleSend = (value: string) => {
    onSend?.(value, temperature);
  };

  useEffect(() => {
    adjustTextAreaHeight();
  }, []);

  return (
    <>
      <Box
        style={{
          position: "relative",
          marginLeft: "10px",
          marginRight: "10px",
        }}
      >
        <StyledTextArea
          ref={textAreaRef}
          onKeyDown={handleKeyDown}
          onInput={handleInput}
          fill={true}
          size="medium"
          title="Press enter to send message"
          placeholder="Prompt magic goes here..."
        />
        <SendButton
          icon={<Send />}
          size="large"
          onClick={() => {
            handleSend(textAreaRef.current!.value);
            textAreaRef.current!.value = "";
            adjustTextAreaHeight();
          }}
        />
      </Box>
      <RangeContainer direction="row" gap="20px">
        <RangeText>solid</RangeText>
        <div style={{ flexGrow: 1 }}>
          <RangeInput
            min={0}
            max={100}
            value={temperature * 100}
            title="By adjusting the temperature setting, you can customize the AI's behavior to better suit your needs or preferences. For example, if you want more creative and diverse responses, you can increase the temperature. If you prefer more consistent and deterministic responses, you can lower the temperature."
            onChange={(event) =>
              setTemperature(Number.parseInt(event.target.value) / 100)
            }
          />
        </div>
        <RangeText>creative</RangeText>
      </RangeContainer>
    </>
  );
};
