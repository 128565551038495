import { ApplicationInsights } from "@microsoft/applicationinsights-web";

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: "e06ea687-d672-4a82-b884-2c50d3544bc7",
    enableAutoRouteTracking: true, // Enable automatic tracking of user activity
  },
});

appInsights.loadAppInsights();
appInsights.trackPageView();

export default appInsights;
