import React, { useRef, useState, useCallback } from "react";
import { Box, Avatar, Text, Button } from "grommet";
import * as Icons from "grommet-icons";
import styled from "styled-components";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { Message } from "../types";
import ReactMarkdown from "react-markdown";
import { Clipboard, Checkmark } from "grommet-icons";

const IconWrapper = styled(Box)`
  flex-shrink: 0;
  flex-grow: 0;
  align-self: flex-start;
`;

const StyledMarkdown = styled(ReactMarkdown)`
  p {
    max-width: 100%;
  }
`;

const DeleteButton = styled(Button)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const MessageList = ({
  messages,
  refs,
  onDeleteMessage,
}: {
  messages: Message[];
  refs: React.RefObject<HTMLDivElement>[];
  onDeleteMessage?: (index: number) => void;
}) => {
  const [copySuccess, setCopySuccess] = useState(false);
  const codeRef = useRef<HTMLDivElement>(null);

  const handleCopy = useCallback(async () => {
    if (codeRef.current) {
      try {
        await navigator.clipboard.writeText(codeRef.current.innerText);
        setCopySuccess(true);
      } catch (err) {
        console.error("Failed to copy text: ", err);
      }
    }
  }, [codeRef]);

  return (
    <Box overflow="auto">
      {messages.map((item, i) => (
        <Box
          key={i}
          direction="row"
          pad="medium"
          background={`light-${i % 2}`}
          gap="medium"
          align="center"
          ref={refs[i]}
          style={{ position: "relative" }}
        >
          <IconWrapper>
            <Avatar background="brand">
              {item.role === "user" ? (
                <Icons.User color="text-strong" />
              ) : (
                <Icons.Robot />
              )}
            </Avatar>
          </IconWrapper>
          <Box style={{ flexGrow: 1 }}>
            {item.role === "user" ? (
              <Text style={{ whiteSpace: "pre-wrap" }}>{item.content}</Text>
            ) : (
              <StyledMarkdown
                children={item.content}
                components={{
                  code({ node, inline, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || "");
                    return !inline && match ? (
                      <Box
                        style={{
                          position: "relative",
                        }}
                      >
                        <div ref={codeRef} style={{ paddingRight: "0" }}>
                          <SyntaxHighlighter
                            children={String(children).replace(/\n$/, "")}
                            language={match[1]}
                            PreTag="div"
                          />
                        </div>

                        <Button
                          icon={
                            copySuccess ? (
                              <Checkmark size="small" />
                            ) : (
                              <Clipboard size="small" />
                            )
                          }
                          alignSelf="start"
                          onClick={handleCopy}
                          style={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                          }}
                        />
                      </Box>
                    ) : (
                      <code {...props} className={className}>
                        {children}
                      </code>
                    );
                  },
                }}
              />
            )}
          </Box>

          <DeleteButton
            icon={<Icons.Trash size="small" />}
            alignSelf="start"
            onClick={() => onDeleteMessage?.(i)}
          />
        </Box>
      ))}
    </Box>
  );
};
