import React, { useLayoutEffect, useMemo, useState } from "react";
import {
  Box,
  Header,
  Page,
  PageContent,
  Text,
  Spinner,
  Notification,
} from "grommet";
import styled from "styled-components";
import { Footer, MessageList } from "./components";
// import appInsights from "./appInsights";
import { Message } from "./types/message";

const StyledHeader = styled(Header)`
  background-color: ${(props) => props.theme.global.colors.brand.light};
  padding: ${(props) => props.theme.global.edgeSize.medium};
  justify-content: center;
`;

const PageContentWrapper = styled(PageContent)`
  margin-bottom: 130px;
  max-width: 1000px;
`;

function App() {
  const [messages, setMessages] = useState<Message[]>([]);
  const [, setMessage] = useState<Message | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  // const [, setUserId] = useState<string | null>(null);

  const refs: React.RefObject<HTMLDivElement>[] = useMemo(
    () => messages.map(() => React.createRef<HTMLDivElement>()),
    [messages]
  );

  // const fetchUserId = async () => {
  //   try {
  //     const response = await fetch("/.auth/me");
  //     const data = await response.json();
  //     if (data?.clientPrincipal?.userId) {
  //       setUserId(data.clientPrincipal.userId);
  //       appInsights.setAuthenticatedUserContext(
  //         data.clientPrincipal.userId,
  //         data.clientPrincipal.userDetails,
  //         true
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Error fetching user ID:", error);
  //   }
  // };

  const handleDeleteMessage = (index: number) => {
    setMessages(messages.filter((_, i) => i !== index));
  };

  const handleSend = (value: string, temperature: number) => {
    if (!value) return;
    const newMessages = [...messages, { role: "user", content: value }];
    setMessages(newMessages);
    setMessage(null);
    setLoading(true);
    (async () => {
      try {
        const response = await fetch("/api/chat", {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({ temperature, messages: newMessages }),
        });
        const data = await response.json();
        if (!response.ok) {
          const message = `An error occured: ${
            data?.error?.message || "unknown error"
          }`;
          setError(message);
          setLoading(false);
          return;
        }

        setMessages([...newMessages, data.choices[0].message]);
      } catch (error: any) {
        setError(error.message);
      }
      setLoading(false);
    })();
  };

  // useEffect(() => {
  //   fetchUserId();
  // }, []);

  useLayoutEffect(() => {
    if (messages.length === 0) return;
    refs[refs.length - 1].current?.scrollIntoView({
      behavior: "smooth",
    });
  }, [messages, refs]);

  return (
    <Page overflow={{ horizontal: "hidden" }}>
      <StyledHeader>
        <Text size="large">ChatGPT by Hololux</Text>
      </StyledHeader>
      {error && (
        <Notification
          title="Error"
          message={error}
          onClose={() => {
            setError(null);
          }}
        />
      )}
      <Box overflow="auto">
        <PageContentWrapper fill="horizontal" pad="small">
          <MessageList
            messages={messages}
            refs={refs}
            onDeleteMessage={handleDeleteMessage}
          />
          {loading && (
            <Box align="center" pad="10px">
              <Spinner size="medium" />
            </Box>
          )}
        </PageContentWrapper>
        <Footer onSend={handleSend} />
      </Box>
    </Page>
  );
}
export default App;
