import React from "react";
import { Box, Footer as GrommetFooter, Text } from "grommet";
import styled from "styled-components";
import { Prompt } from "./Prompt";

const StyledFooter = styled(GrommetFooter)`
  position: fixed;
  bottom: 0;
  justify-content: center;
  width: 100%;
  padding-bottom: 0;
`;

const StyledBox = styled(Box)`
  background-color: white;
  width: 100%;
  max-width: 750px;
`;

const FooterTextWrapper = styled(Box)`
  text-align: center;
  padding: 10px;
`;

interface FooterProps {
  onSend?: (value: string, temperature: number) => void;
}

export const Footer: React.FC<FooterProps> = ({ onSend }) => (
  <StyledFooter>
    <StyledBox>
      <Prompt onSend={onSend} />
      <FooterTextWrapper>
        <Text size="small">© hololux.com</Text>
      </FooterTextWrapper>
    </StyledBox>
  </StyledFooter>
);
